<div [ngClass]="className"
     class="iw-form-group iw-form-group-primeng">
    <div *ngIf="label"
         class="iw-form-group-label"
         for="item">{{ label | translate }}
    </div>
    <div class="p-inputgroup iw-input flex flex-h">
        <p-calendar #control
                    (onSelect)="onSelectEvent($event)"
                    [(ngModel)]="primeNgValue"
                    [appendTo]="'body'"
                    [dateFormat]="dateFormat"
                    [disabled]="isDisabled"
                    [firstDayOfWeek]="1"
                    [hourFormat]="24"
                    [inputStyle]="{'background-color': bckClrCalendar}"
                    [maxDate]="maxDate"
                    [minDate]="minDate"
                    [monthNavigator]="true"
                    [readonlyInput]="isReadOnly"
                    [showTime]="showTime"
                    [yearNavigator]="true"
                    [yearRange]="yearRange"
                    class="iw-date-picker"></p-calendar>
        <input *ngIf="showWeekDay && !showWeekNumber"
               [value]="weekDay | translate"
               disabled
               style="text-align: center; width: 50px;">
        <input *ngIf="showWeekDay && showWeekNumber"
               [value]="(weekDay | translate) + weekNumber"
               disabled
               style="text-align: center; width: 100px;">
        <input
                *ngIf="!showWeekDay && showWeekNumber"
                [ngStyle]="{'background-color': bckColor}"
                [value]="weekNumber"
                disabled
                style="text-align: center; width: 50px;">
    </div>
</div>
