import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IwPpabscodeComboboxComponent} from '@app/sam-base/components/ppabscode-combo-box/ppabscode-combo-box.component';
import {RadioListComponent} from '@app/sam-base/components/radio-list/radio-list.component';
import {FullCalendarModule} from "@fullcalendar/angular";
import {TranslateModule} from '@ngx-translate/core';
import {IwCantonDropdownComponent} from '@sam-base/components/dropdown/iw-cantom-dropdown.component';
import {NpaLieuComponent} from '@sam-base/components/iw-npa-lieu/npa-lieu.component';
import {
    IwCellStatusColorComponent
} from '@sam-base/components/iw-table/components/iw-table-column/iw-cell-status/iw-cell-status-color.component';
import {
    IwCellStatusMissionsComponent
} from '@sam-base/components/iw-table/components/iw-table-column/iw-cell-status/iw-cell-status-missions.component';
import {DurationInputDirective} from '@sam-base/core';

import {IwAutofocusDirective} from '@sam-base/core/directives/iw-autofocus.directive';
import {IwCellPipe} from '@sam-base/core/pipes/iw-cell.pipe';
import {IwDatetimePipe} from '@sam-base/core/pipes/iw-datetime.pipe';
import {IwTextfieldBankComponent} from '@shared/widgets/form-components/iw-textfield-bank/iw-textfield-bank.component';
import {
    IwMultiautocompleteSelectComponent
} from '@shared/widgets/form-components/multiautocomplete-select/iw-multiautocomplete-select.component';
import {AngularIbanModule} from 'angular-iban';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {NgxPaginationModule} from 'ngx-pagination';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MenuModule} from 'primeng/menu';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PasswordModule} from 'primeng/password';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import {IwPeriodeDropdownComponent} from './dropdown/period/iw-periode-dropdown.component';
import {EntityDropdownComponent} from './entity-dropdown/entity-dropdown.component';
import {IwFormStatusComponent} from './form-handler/form-status/form-status.component';
import {IwGridProfileDropdownComponent} from './grid-profile-dropdown/grid-profile-dropdown.component';
import {IwBadgeComponent} from './iw-badge/iw-badge.component';
import {IwBusyComponent} from './iw-busy/iw-busy.component';
import {IwButtonComponent} from './iw-button/iw-button.component';
import {IwCalendarTooltipComponent} from './iw-calendar/iw-calendar-tooltip/iw-calendar-tooltip.component';
import {IwCalendarComponent} from './iw-calendar/iw-calendar.component';
import {IwCctElemDropdownComponent} from './iw-cct-elem-dropdown/iw-cct-elem-dropdown.component';
import {IwCheckboxComponent} from './iw-checkbox/iw-checkbox.component';
import {IwContextMenuComponent} from './iw-context-menu/iw-context-menu.component';
import {IwDatePickerComponent} from './iw-date-picker/iw-date-picker.component';
import {IwDialogComponent} from './iw-dialog/iw-dialog.component';
import {IwDropdownComponent} from './iw-dropdown/iw-dropdown.component';
import {EmailTextfieldComponent} from './iw-email-textfield/email-textfield.component';
import {IwEnumDropdownComponent} from './iw-enum-dropdown/iw-enum-dropdown.component';
import {IwEnumTextFieldComponent} from './iw-enum-textfield/iw-enum-textfield.component';
import {IwGridColumnConfigComponent} from './iw-grid-column-config/iw-grid-column-config.component';
import {IwHelpMenuComponent} from './iw-help-menu/iw-help-menu.component';
import {IwHoursInputComponent} from './iw-hours-input/iw-hours-input.component';
import {IwImgUploaderComponent} from './iw-img-uploader/iw-img-uploader.component';
import {IwInputAvsMaskComponent} from './iw-input-avs-mask/iw-input-avs-mask.component';
import {IwInputSwitchComponent} from './iw-input-switch/iw-input-switch.component';
import {IwMonthPickerComponent} from './iw-month-picker/iw-month-picker.component';
import {IwMultiSelectComponent} from './iw-multi-select/iw-multi-select.component';
import {IwMultilineViewComponent} from './iw-multiline-view/iw-multiline-view.component';
import {NpaTextfieldComponent} from './iw-npa-textfield/npa-textfield.component';
import {IwPhoneTextFieldComponent} from './iw-phone-textfield/iw-phone-textfield.component';
import {IwPlanningTooltipComponent} from './iw-planning-tooltip/iw-planning-tooltip.component';
import {IwProgressBarComponent} from './iw-progress-bar/iw-progress-bar.component';
import {IwRadiofieldComponent} from './iw-radiofield/iw-radiofield.component';
import {IwRestGridComponent} from './iw-rest-grid/iw-rest-grid.component';
import {IwSearchBoxComponent} from './iw-search-box/iw-search-box.component';
import {IwSearchTooltipComponent} from './iw-search-tooltip/iw-search-tooltip.component';
import {IwSmartGridComponent} from './iw-smart-grid/iw-smart-grid.component';
import {IwTabComponent} from './iw-tab/iw-tab.component';
import {IwTableBodyRowComponent} from './iw-table/components/iw-table-body-row/iw-table-body-row.component';
import {IwTableBodyComponent} from './iw-table/components/iw-table-body/iw-table-body.component';
import {
    IwCellActivePeriodComponent
} from './iw-table/components/iw-table-column/iw-cell-active-period/iw-cell-active-period.component';
import {
    IwCellStatusClientComponent
} from './iw-table/components/iw-table-column/iw-cell-status/iw-cell-status-client.component';
import {
    IwCellStatusCommandesComponent
} from './iw-table/components/iw-table-column/iw-cell-status/iw-cell-status-commandes.component';
import {
    IwCellStatusEmployeeComponent
} from './iw-table/components/iw-table-column/iw-cell-status/iw-cell-status-employee.component';
import {
    IwCellStatusRapmisactComponent
} from './iw-table/components/iw-table-column/iw-cell-status/iw-cell-status-rapmisact.component';
import {IwTableCellComponent} from './iw-table/components/iw-table-column/iw-table-cell.component';
import {IwTableDetailsComponent} from './iw-table/components/iw-table-details/iw-table-details.component';
import {IwTableHeaderComponent} from './iw-table/components/iw-table-header/iw-table-header.component';
import {IwTableConfigComponent} from './iw-table/iw-table-config/iw-table-config.component';
import {IwTableComponent} from './iw-table/iw-table.component';
import {IwTextareaComponent} from './iw-textarea/iw-textarea.component';
import {IwTextBoxComponent} from './iw-textbox/iw-textbox.component';
import {IwTextFieldAutocompleteComponent} from './iw-textfield-autocomplete/iw-textfield-autocomplete.component';
import {
    IwTextFieldMultiautocompleteComponent
} from './iw-textfield-multiautocomplete/iw-textfield-multiautocomplete.component';
import {IwTextFieldComponent} from './iw-textfield/iw-textfield.component';
import {ModalFormComponent, ModalTextAreaComponent} from './modal';
import {IwMouseOverDetailsComponent} from './mouse-over-details/mouse-over-details.component';

export const comps: any[] = [
    EmailTextfieldComponent,
    EntityDropdownComponent,
    IwAutofocusDirective,
    IwBadgeComponent,
    IwBusyComponent,
    IwButtonComponent,
    IwCalendarComponent,
    IwCalendarTooltipComponent,
    IwCctElemDropdownComponent,
    IwCellActivePeriodComponent,
    IwCellPipe,
    IwDatetimePipe,
    IwCellStatusClientComponent,
    IwCellStatusCommandesComponent,
    IwCellStatusEmployeeComponent,
    IwCellStatusMissionsComponent,
    IwCellStatusRapmisactComponent,
    IwCellStatusColorComponent,
    IwCheckboxComponent,
    IwContextMenuComponent,
    IwDatePickerComponent,
    IwDialogComponent,
    IwDropdownComponent,
    IwEnumDropdownComponent,
    IwEnumTextFieldComponent,
    IwFormStatusComponent,
    IwGridColumnConfigComponent,
    IwGridProfileDropdownComponent,
    IwHelpMenuComponent,
    IwHoursInputComponent,
    IwImgUploaderComponent,
    IwInputAvsMaskComponent,
    IwInputSwitchComponent,
    IwMonthPickerComponent,
    IwMouseOverDetailsComponent,
    IwMultiSelectComponent,
    IwMultiautocompleteSelectComponent,
    IwMultilineViewComponent,
    IwPeriodeDropdownComponent,
    IwPhoneTextFieldComponent,
    IwPlanningTooltipComponent,
    IwPpabscodeComboboxComponent,
    IwProgressBarComponent,
    IwRadiofieldComponent,
    IwRestGridComponent,
    IwSearchBoxComponent,
    IwSearchTooltipComponent,
    IwSmartGridComponent,
    IwTabComponent,
    IwTableBodyComponent,
    IwTableBodyRowComponent,
    IwTableCellComponent,
    IwTableComponent,
    IwTableConfigComponent,
    IwTableDetailsComponent,
    IwTableHeaderComponent,
    IwTextBoxComponent,
    IwTextFieldAutocompleteComponent,
    IwTextFieldComponent,
    IwTextFieldMultiautocompleteComponent,
    IwTextareaComponent,
    IwTextfieldBankComponent,
    ModalFormComponent,
    ModalTextAreaComponent,
    NpaLieuComponent,
    NpaTextfieldComponent,
    RadioListComponent,
    DurationInputDirective,
    IwCantonDropdownComponent];

@NgModule({
    imports: [
        AngularIbanModule,
        AutoCompleteModule,
        ButtonModule,
        CalendarModule,
        CheckboxModule,
        CommonModule,
        ContextMenuModule,
        DialogModule,
        DropdownModule,
        FileUploadModule,
        FormsModule,
        FullCalendarModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextareaModule,
        InputNumberModule,
        PasswordModule,
        MenuModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgxPaginationModule,
        OverlayModule,
        OverlayPanelModule,
        ProgressBarModule,
        RadioButtonModule,
        ReactiveFormsModule,
        TableModule,
        TooltipModule,
        TranslateModule],
    declarations: [...comps],
    exports: [...comps],
    providers: [
        provideNgxMask(),
        CurrencyPipe]
})
export class SamComponentsModule {
}
